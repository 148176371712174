import Vue from "vue";
import App from "./App.vue";
import axios from 'axios';
import linkify from 'vue-linkify';

Vue.prototype.my_profile = window.venus_chat.my_profile;
Vue.prototype.settings = window.venus_chat.settings;
Vue.prototype.label = window.venus_chat.labels;
Vue.prototype.ajax_url = window.venus_chat.ajax_url;
Vue.prototype.plugin_url = window.venus_chat.plugin_url;
Vue.prototype.is_user_logged_in = window.venus_chat.is_user_logged_in;
Vue.prototype.$api = axios.create({
  baseURL: window.venus_chat.ajax_url //"http://localhost/wp-chat/wp-admin"
})

Vue.config.productionTip = false;

Vue.directive('linkified', linkify);
Vue.mixin({
  data: function() {
    return {      
      monthsName: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
    }
  },
  methods: {    
    formatDate(date) {
      let d = new Date(date.replace(/-/g,'/') + ' UTC');
      let month = this.monthsName[d.getMonth()];
      let day = ("0" + d.getDate()).slice(-2); 
      let hours = ("0" + d.getHours()).slice(-2); 
      let ampm = hours >= 12 ? "pm" : "am";
      let minutes = ("0" + d.getMinutes()).slice(-2); 
      hours = hours >= 12 ? (hours - 12) : hours;
      return month + ' ' + day + ', '+ hours +':'+ minutes +' '+ ampm;
    }
  }
})

document.addEventListener('DOMContentLoaded', () => {
new Vue({
  render: h => h(App)
}).$mount("#buc__chat-entry");
});

export { default as ChatApp } from './components/ChatApp.vue';