<template>
  <div id="wp-chat-frame" 
    v-if="launcherVisible"
    :class="'buc__theme-' + settings.appearance_theme" 
    :style="[settings.appearance_theme == 'custom' ? customCssVar : '']" >
    <div
      class="buc__chat-launcher"
      @click="toggleLauncher"
    >
    <span v-if="computedTotalUnreadCount" class="buc__unread-count">{{(computedTotalUnreadCount > 9) ? '9+' : computedTotalUnreadCount}}</span>
      <img
        v-if="!usersVisible"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 612' fill='%23fff'%3E%3Cdefs/%3E%3Cpath d='M401.625 325.125h-191.25c-10.557 0-19.125 8.568-19.125 19.125s8.568 19.125 19.125 19.125h191.25c10.557 0 19.125-8.568 19.125-19.125s-8.568-19.125-19.125-19.125zm38.25-114.75h-267.75c-10.557 0-19.125 8.568-19.125 19.125s8.568 19.125 19.125 19.125h267.75c10.557 0 19.125-8.568 19.125-19.125s-8.568-19.125-19.125-19.125zM306 0C137.012 0 0 119.875 0 267.75c0 84.514 44.848 159.751 114.75 208.826V612l134.047-81.339c18.552 3.061 37.638 4.839 57.203 4.839 169.008 0 306-119.875 306-267.75S475.008 0 306 0zm0 497.25c-22.338 0-43.911-2.601-64.643-7.019l-90.041 54.123 1.205-88.701C83.5 414.133 38.25 345.513 38.25 267.75c0-126.741 119.875-229.5 267.75-229.5s267.75 102.759 267.75 229.5S453.875 497.25 306 497.25z'/%3E%3C/svg%3E"
        alt="default"
        class="buc__chat-launcher-open"
      />
      <img
        v-else
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.001 512.001' fill='%23fff' %3E%3Cdefs/%3E%3Cpath d='M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z'/%3E%3C/svg%3E"
        alt="default"
        class="buc__chat-launcher-closed"
      />
    </div>
    <transition
      name="buc__slide-animation"
      enter-active-class="buc__animated buc__animate__slideInUp"
      leave-active-class="buc__animated buc__animate__slideOutDown"
      >
      <UsersPanel v-if="usersVisible" 
      @showChatWindow="showChatWindow" 
      :friends="friends"
      :groups="groups"
      :chats="computedRecentChats"/>
    </transition>

    <div id="buc__chat-tabs-container">
      <div v-for="(head, index) in computedChatHeads" :key="index" class="buc__chat-tabs-list">
        <div class="buc__chat-tab" :style="{right : head.right + 'px'}">
          <ContentPanel @closeChatWindow="closeChatWindow(index)"
            @minimizeChatWindow="minimizeChatWindow(index)"
            @blockUser="blockUser(head.key)"
            @muteGroup="muteGroup(head.key)"
            @setLocalMessageSeen="setLocalMessageSeen"
            @openModal="openModal"
            :isMinimized="head.isMinimized"
            :currentPartcipant="head.participant"
            :currentChat="head.chats"
            :currentChatReplies="head.replies"
            :senderLatestMsgID="head.isGroup == 0 ? head.senderLatestMsgID : -1"
            :isGroup="head.isGroup"
            :groupMembers="groupParticipants"
            :ref="'chatWindow' + index"/>
        </div>

        <div class="buc__chat-bubble" :style="{bottom : head.bottom + 'px'}">
          <img @click="toggleChatWindow(index)" :src="head.participant.avatar_url" alt="" />
          <span v-if="head.unreadCount" class="buc__unread-count">{{(head.unreadCount > 9) ? '9+' : head.unreadCount}}</span>
          <div class="buc__chat-bubble-close" @click="closeChatWindow(index)">
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="m284.29 256 221.86-221.86c7.811-7.811 7.811-20.475 0-28.285s-20.475-7.811-28.285 0l-221.86 221.86-221.86-221.86c-7.811-7.811-20.475-7.811-28.285 0s-7.811 20.475 0 28.285l221.86 221.86-221.86 221.86c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0 0 14.143 5.857 19.94 19.94 0 0 0 14.143-5.857l221.86-221.86 221.86 221.86c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285l-221.86-221.86z"/>
            </svg>
          </div>
        </div>
      </div>
    </div>        
    <Modal :imageSrc="selectedModalImage" v-if='showModal' @closeModal="showModal = false"></Modal>
  </div>
</template>

<script>
import UsersPanel from "./UsersPanel.vue";
import ContentPanel from "./ContentPanel.vue";
import twemoji from 'twemoji';
import Modal from './Modal.vue';

export default {
  name: "ChatApp",
  data() {
    return {
      launcherVisible: true,
      isExecuted: false,
      usersVisible: this.settings.show_maximised_widget == '1' ? true : false,
      friends: {},
      groups: {},
      groupMembers: {},
      groupParticipants: {},
      userChats: {},
      groupChats: {},
      chatHeads: [],
      dataPayload: {},
      messageSeenList: {},
      showModal: false,
      selectedModalImage: '',
      notificationSound: new Audio(this.settings.buc_custom_sound_url),
      lastMessageId: 0, //till here notification sound has been played and data fetched
      customCssVar: {
        '--buc-primary-color': this.settings.custom_theme_array.buc_primary_color,
        '--buc-primary-shade': this.settings.custom_theme_array.buc_primary_shade,
        '--buc-primary-hover': this.settings.custom_theme_array.buc_primary_hover,
        '--buc-primary-text': this.settings.custom_theme_array.buc_primary_text,
        '--buc-secondary-bg': this.settings.custom_theme_array.buc_secondary_bg,
        '--buc-secondary-text': this.settings.custom_theme_array.buc_secondary_text,
        '--buc-recieved-chat-bg': this.settings.custom_theme_array.buc_recieved_chat_bg,
        '--buc-sent-chat-bg': this.settings.custom_theme_array.buc_sent_chat_bg,
        '--buc-recieved-chat-text': this.settings.custom_theme_array.buc_recieved_chat_text,
        '--buc-sent-chat-text': this.settings.custom_theme_array.buc_sent_chat_text,
        '--buc-chat-bg': this.settings.custom_theme_array.buc_chat_bg,
        '--buc-status-online': this.settings.custom_theme_array.buc_status_online,
        '--buc-status-away': this.settings.custom_theme_array.buc_status_away,
        '--buc-status-busy': this.settings.custom_theme_array.buc_status_busy,
        '--buc-status-offline': this.settings.custom_theme_array.buc_status_offline,
        '--buc-unread-marker': this.settings.custom_theme_array.buc_unread_marker
      }
    }
  },
  created() {
    const vm = this;
    this.notificationSound.onerror = function() {
        vm.notificationSound = new Audio(vm.plugin_url + 'assets/audio/noti-sound.mp3');
    };
    
    this.getFriends();
    setInterval(function() {
      vm.getFriends()
    }, this.settings.user_status_refresh_interval); //Ajax call in interval to refresh friends data
  },
  computed: {
    /** Computes chat bubbles position and unread count */
    computedChatHeads() {
      let ind = -1;
      this.chatHeads.forEach((e,i) => {
        if(this.usersVisible && i == 0) ind++;     
        if(!e.isMinimized) ind++;
        e.participant = e.isGroup == 0 ? this.friends[e.key] : this.groups[e.key];

        if(e.isGroup == 0 && this.userChats[this.friends[e.key].id]) {
          e.chats = this.userChats[this.friends[e.key].id].messages;
          e.replies = this.userChats[this.friends[e.key].id].replies;
          e.unreadCount = this.userChats[this.friends[e.key].id].messages.filter((item) => { 
                        return (item.isread == 0 && item.reciever == this.my_profile.id)
                      }).length;
          e.senderLatestMsgID = this.userChats[this.friends[e.key].id].senderLatestMsgID;
        } else if (e.isGroup == 1 && this.groupChats[this.groups[e.key].id]){
          e.chats = this.groupChats[this.groups[e.key].id].messages;
          e.replies = this.groupChats[this.groups[e.key].id].replies;
        }

        e.bottom = 70 + i * 50;
        if(!e.isMinimized) {
          e.right = 70 + ind * 310;
        } else {
          e.right = -400;
        }
      });
      return this.chatHeads;
    },
    computedRecentChats() {
      let ind;      
      let recChats = [];

      for(let elem of Object.entries(this.userChats)) {
        /** Fill recent chats tab for users panel */
        ind = elem[1].messages.length - 1;
        recChats.push({
          id: elem[0],
          is_group: 0,
          fullname: this.friends[elem[0]].fullname,
          last_msg_id: elem[1].messages[ind].id,
          last_msg: elem[1].messages[ind].type == 'text' ? elem[1].messages[ind].message : this.label.media,
          unread_count: elem[1].messages.filter((item) => { 
            return (item.isread == 0 && item.reciever == this.my_profile.id)
          }).length,
          sent_by: ( elem[1].messages[ind].sender == this.my_profile.id ) ? this.label.you : this.friends[elem[0]].fullname.split(' ', 1).toString()
        })
      }

      for(let elem of Object.entries(this.groupChats)) {
        /** Fill recent chats tab for users panel */
        ind = elem[1].messages.length - 1;
        recChats.push({
          id: elem[0],
          is_group: 1,
          fullname: this.groups[elem[0]].group_name,
          last_msg_id: elem[1].messages[ind].id,
          last_msg: elem[1].messages[ind].type == 'text' ? elem[1].messages[ind].message : this.label.media,
          unread_count: 0,
          sent_by: ( elem[1].messages[ind].sender == this.my_profile.id ) ? this.label.you : this.groupParticipants[elem[1].messages[ind].sender].name
        })
      }
      return recChats.sort((a,b)=> { return b.last_msg_id - a.last_msg_id });
    },
    computedTotalUnreadCount() {
      let count = 0;
      this.computedRecentChats.forEach(e => {
        count += e.unread_count;
        if(count > 9) return count;
      })
      return count;
    }
  },
  watch: {
    /** Plays notification on new message and updates unread count */
    dataPayload: function(data) {
      let obj = {};
      for(let elem of Object.entries(data.user_chats)) {
        if(elem[1].messages.length) {
          /** Parse emoji unicode to img tags */
          elem[1].messages.forEach((e)=> {
            e.message = twemoji.parse(e.message);
            if(this.my_profile.sound && this.lastMessageId && e.reciever == this.my_profile.id) {
              this.notificationSound.play(); 
            }
          });
          elem[1].replies.forEach((e)=> {
            e.message = twemoji.parse(e.message);
          });
          
          let senderLatestMsgIndex = elem[1].messages.findIndex(e => e.reciever == this.my_profile.id && e.isread == 0);  
          let senderLatestMsgID = senderLatestMsgIndex != -1 ? elem[1].messages[senderLatestMsgIndex].id : -1;       
          let recieverLastReadMsgIndex = elem[1].messages.findIndex(e=> e.sender == this.my_profile.id && e.isread == 1);
          if(recieverLastReadMsgIndex != -1) {
            for(var i = elem[1].messages.length - 1; i >= 0 ; i--) {
              if(elem[1].messages[i].sender == this.my_profile.id
              && elem[1].messages[i].id <= elem[1].messages[recieverLastReadMsgIndex].id) {
                if(elem[1].messages[i].isread == 1) break;
                elem[1].messages[i].isread = 1;
              }
            }
          }
          elem[1].messages = elem[1].messages.sort((a, b) => a.id - b.id);
          obj[elem[0]] = {
              messages: this.userChats[elem[0]] ? this.userChats[elem[0]].messages.concat(elem[1].messages) : elem[1].messages,
              replies: this.userChats[elem[0]] ? this.userChats[elem[0]].replies.concat(elem[1].replies) : elem[1].replies,
              senderLatestMsgID: senderLatestMsgID
          }
          this.userChats = {...this.userChats, ...obj};
          obj = {};
        }
      }

      for(let elem of Object.entries(data.group_chats)) {
        if(elem[1].messages.length) {
          /** Parse emoji unicode to img tags */
          elem[1].messages.forEach((e)=> {
            e.message = twemoji.parse(e.message);
            if(this.my_profile.sound && this.groups[elem[0]].sound && this.lastMessageId && e.sender != this.my_profile.id) {
              this.notificationSound.play(); 
            }
          });
          elem[1].replies.forEach((e)=> {
            e.message = twemoji.parse(e.message);
          });

          elem[1].messages = elem[1].messages.sort((a, b) => a.id - b.id);
          obj[elem[0]] = {
            messages: this.groupChats[elem[0]] ? this.groupChats[elem[0]].messages.concat(elem[1].messages) : elem[1].messages,
            replies: this.groupChats[elem[0]] ? this.groupChats[elem[0]].replies.concat(elem[1].replies) : elem[1].replies
          }
          this.groupChats = {...this.groupChats, ...obj};
          obj = {};          
        }
      }
      this.lastMessageId = data.max_message_id;
    },
    messageSeenList: function(data) {
      for(let elem of Object.entries(data)) {
        if(elem[1]) {
          for(var i = this.userChats[elem[0]].messages.length - 1; i >= 0; i--) {
            if(this.userChats[elem[0]].messages[i].sender == this.my_profile.id
            && this.userChats[elem[0]].messages[i].id <= elem[1]) {
              if(this.userChats[elem[0]].messages[i].isread == 1) break;
              this.userChats[elem[0]].messages[i].isread = 1;
            }
          }
        }
      }      
    },
    groupMembers: function(data) {
      for(let elem of Object.entries(data)) {
        if(!this.groupParticipants[elem[1].user_id]) {
          this.groupParticipants[elem[1].user_id] = {
            name: elem[1].name
          }
        }
      }
    }
  },
  methods: {
    /** Checks if the device is mobile */
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    /** Open chat window */
    showChatWindow: function(index, isGroup) {
      let foundInd = this.chatHeads.findIndex(obj => obj.key == index && obj.isGroup == isGroup) //if chat already opened
      if(foundInd != -1) {
        this.chatHeads[foundInd].isMinimized = !this.chatHeads[foundInd].isMinimized;
        if(this.isMobile()) this.usersVisible = false;
        return;
      }

      let chatObj = {
        key: index,
        isGroup: isGroup,
        isMinimized: false,
        participant: isGroup == 0 ? this.friends[index] : this.groups[index],
        chats: [],
        replies: [],
        unreadCount: 0
      };

      if(isGroup == 0 && this.userChats[this.friends[index].id]) {
        chatObj.chats = this.userChats[this.friends[index].id].messages;
        chatObj.replies = this.userChats[this.friends[index].id].replies;
        chatObj.unreadCount = this.userChats[this.friends[index].id].messages.filter((item) => { 
                      return (item.isread == 0 && item.reciever == this.my_profile.id)
                    }).length;
        chatObj.senderLatestMsgID = this.userChats[this.friends[index].id].senderLatestMsgID;
      }else if (isGroup == 1 && this.groupChats[this.groups[index].id]){
        chatObj.chats = this.groupChats[this.groups[index].id].messages;
        chatObj.replies = this.groupChats[this.groups[index].id].replies;
      }

      if(this.isMobile()) {
        this.usersVisible = false;
        this.chatHeads.forEach((e) => {
          e.isMinimized = true;
        });
      } // If mobile minimize all open chats and users panel

      if(this.chatHeads.length > 2 ){
        this.chatHeads.shift();
        for(let i=0; i < this.chatHeads.length; i++) {
          this.$refs['chatWindow' + i][0].clearData(this.$refs['chatWindow' + (i + 1)][0]._data);
        }
        this.$refs['chatWindow' + this.chatHeads.length][0].clearData(null);
        this.chatHeads.push(chatObj);
      } else {
        this.chatHeads.push(chatObj);
      } // If 3 chat heads are opened then relace the oldest one
    },
    toggleChatWindow(ind) {
      if(this.chatHeads[ind]) {
        this.chatHeads[ind].isMinimized = !this.chatHeads[ind].isMinimized;
      }
      if(this.isMobile()) {
        this.usersVisible = false;
        this.chatHeads.forEach((e,i) => {
          if(i != ind)
          e.isMinimized = true;
        });
      } // If mobile minimize all open chats
    },
    closeChatWindow(ind) {
      if (ind !== -1){
        for(let i=ind; i < this.chatHeads.length - 1; i++) {
          this.$refs['chatWindow' + i][0].clearData(this.$refs['chatWindow' + (i + 1)][0]._data);
        }
        this.chatHeads.splice(ind, 1);
      }
    },
    minimizeChatWindow(ind) {
      this.chatHeads[ind].isMinimized = true;
    },
    toggleLauncher() {
      if(this.usersVisible || (this.isMobile() && !this.usersVisible)) {
        this.chatHeads.forEach((e) => {
          e.isMinimized = true;
        });
      }  
      this.usersVisible = !this.usersVisible;
    },
    setLocalMessageSeen(userID, msgID) {  
      for(var i = this.userChats[userID].messages.length - 1; i >= 0; i--) {
        if(this.userChats[userID].messages[i].reciever == this.my_profile.id
        && this.userChats[userID].messages[i].id <= msgID) {
          if(this.userChats[userID].messages[i].isread == 1) break;
          this.userChats[userID].messages[i].isread = 1;
        }
      }
    },
    getFriends() {
      this.$api
      .get("", {params: {action: 'buc_get_contacts'}})
      .then(res => {
        this.friends = res.data.data.friends;
        this.groups = res.data.data.groups;
        this.groupMembers = res.data.data.group_members;
        if(!this.lastMessageId && !this.isExecuted) {
          this.isExecuted = true;
          this.getRecentChats();
        }
      }).catch(err => console.log(err));
    },
    getRecentChats() {
      this.$api
      .get("", {params: {
        action: 'buc_get_latest_chats',
        message_id : this.lastMessageId
      }})
      .then(res => {
        if(res.data.success) {
          this.dataPayload = res.data.data;
        } else {
          this.messageSeenList = res.data.data;
        }
        setTimeout(
          this.getRecentChats, /* Try again after.. */
          this.settings.chat_refresh_interval);
      })
      .catch((e) => {
        console.log('catch', e);
        setTimeout(
          this.getRecentChats, /* Try again after.. */
          this.settings.chat_refresh_interval);
      });
    },
    blockUser(key) {      
      if(this.friends[key].is_block) {        
        this.$api
          .get("", {params: {
            action: 'buc_set_unblock_user',
            user_id: key
          }}).then(res => {
            if(res.data.data) this.friends[key].is_block = false;
          }).catch(err => console.log(err));
        } else {
          this.$api
          .get("", {params: {
            action: 'buc_set_block_user',
            user_id: key
          }}).then(res => {
            if(res.data.data) this.friends[key].is_block = true;
          }).catch(err => console.log(err));
      }
    },
    muteGroup(key) {
      var obj;
      obj = {
        action: 'buc_set_group_sound_notification',
        group_id: key,
        enable: this.groups[key].sound ? 0 : 1
      }
      this.$api
        .get("", {params: obj}).then(res => {
          if(res.data.data) this.groups[key].sound = this.groups[key].sound ? false : true;
        }).catch(err => console.log(err));
    },
    openModal(src) {
      this.showModal = true;
      this.selectedModalImage = src;
    }
  },
  components: {
    UsersPanel,
    ContentPanel,
    Modal
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@-webkit-keyframes slideInUp {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

@keyframes slideInUp {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }
}

@-webkit-keyframes slideOutDown {
    0% {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }
}

@keyframes slideOutDown {
    0% {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.buc__theme-slate_blue {
  --buc-primary-color: #2c3e50;
  --buc-primary-shade: #32465a;
  --buc-primary-hover: #435f7a;
  --buc-primary-text: #f5f5f5;
  --buc-secondary-bg: #f5f5f5;
  --buc-secondary-text: #292929;
  --buc-recieved-chat-bg: #435f7a;
  --buc-sent-chat-bg: #f5f5f5;
  --buc-recieved-chat-text: #f5f5f5;
  --buc-sent-chat-text: #253443;
  --buc-chat-bg: #E6EAEA;
  --buc-status-online: #2ecc71;
  --buc-status-away: #f1c40f;
  --buc-status-busy: #e74c3c;
  --buc-status-offline: #95a5a6;
  --buc-unread-marker: #2ecc71;
}

.buc__theme-purple {
  --buc-primary-color: #8A609F;
  --buc-primary-shade: #755287;
  --buc-primary-hover: #9c78ae;
  --buc-primary-text: #f5f5f5;
  --buc-secondary-bg: #f5f5f5;
  --buc-secondary-text: #292929;
  --buc-recieved-chat-bg: #936ca6;
  --buc-sent-chat-bg: #f5f5f5;
  --buc-recieved-chat-text: #f5f5f5;
  --buc-sent-chat-text: #253443;
  --buc-chat-bg: #ede7f0;
  --buc-status-online: #2ecc71;
  --buc-status-away: #f1c40f;
  --buc-status-busy: #e74c3c;
  --buc-status-offline: #95a5a6;
  --buc-unread-marker: #2ecc71;
}

.buc__theme-blue {
  --buc-primary-color: #2779BF;
  --buc-primary-shade: #2068A3;
  --buc-primary-hover: #4796da;
  --buc-primary-text: #f5f5f5;
  --buc-secondary-bg: #f5f5f5;
  --buc-secondary-text: #292929;
  --buc-recieved-chat-bg: #378dd6;
  --buc-sent-chat-bg: #f5f5f5;
  --buc-recieved-chat-text: #f5f5f5;
  --buc-sent-chat-text: #253443;
  --buc-chat-bg: #eaf3fb;
  --buc-status-online: #2ecc71;
  --buc-status-away: #f1c40f;
  --buc-status-busy: #e74c3c;
  --buc-status-offline: #95a5a6;
  --buc-unread-marker: #2ecc71;
}

.buc__theme-teal {
  --buc-primary-color: #36889f;
  --buc-primary-shade: #3B95AE;
  --buc-primary-hover: #4da9c3;
  --buc-primary-text: #f5f5f5;
  --buc-secondary-bg: #f5f5f5;
  --buc-secondary-text: #292929;
  --buc-recieved-chat-bg: #4da9c3;
  --buc-sent-chat-bg: #f5f5f5;
  --buc-recieved-chat-text: #f5f5f5;
  --buc-sent-chat-text: #253443;
  --buc-chat-bg: #eff7f9;
  --buc-status-online: #2ecc71;
  --buc-status-away: #f1c40f;
  --buc-status-busy: #e74c3c;
  --buc-status-offline: #95a5a6;
  --buc-unread-marker: #2ecc71;
}

.buc__theme-pink {
  --buc-primary-color: #CD5A91;
  --buc-primary-shade: #AE4D7B;
  --buc-primary-hover: #c84b87;
  --buc-primary-text: #f5f5f5;
  --buc-secondary-bg: #f5f5f5;
  --buc-secondary-text: #292929;
  --buc-recieved-chat-bg: #c84b87;
  --buc-sent-chat-bg: #f5f5f5;
  --buc-recieved-chat-text: #f5f5f5;
  --buc-sent-chat-text: #253443;
  --buc-chat-bg: #f6e1eb;
  --buc-status-online: #2ecc71;
  --buc-status-away: #f1c40f;
  --buc-status-busy: #e74c3c;
  --buc-status-offline: #95a5a6;
  --buc-unread-marker: #2ecc71;
}

.buc__theme-grey {
  --buc-primary-color: #838C91;
  --buc-primary-shade: #6F777B;
  --buc-primary-hover: #8d969a;
  --buc-primary-text: #f5f5f5;
  --buc-secondary-bg: #f5f5f5;
  --buc-secondary-text: #292929;
  --buc-recieved-chat-bg: #798288;
  --buc-sent-chat-bg: #f5f5f5;
  --buc-recieved-chat-text: #f5f5f5;
  --buc-sent-chat-text: #253443;
  --buc-chat-bg: #ebeced;
  --buc-status-online: #2ecc71;
  --buc-status-away: #f1c40f;
  --buc-status-busy: #e74c3c;
  --buc-status-offline: #95a5a6;
  --buc-unread-marker: #2ecc71;
}

.buc__theme-shima {
  --buc-primary-color: #d64543;
  --buc-primary-shade: #d23533;
  --buc-primary-hover: #da5553;
  --buc-primary-text: #f5f5f5;
  --buc-secondary-bg: #f5f5f5;
  --buc-secondary-text: #292929;
  --buc-recieved-chat-bg: #d23533;
  --buc-sent-chat-bg: #f5f5f5;
  --buc-recieved-chat-text: #f5f5f5;
  --buc-sent-chat-text: #253443;
  --buc-chat-bg: #f9e4e4;
  --buc-status-online: #2ecc71;
  --buc-status-away: #f1c40f;
  --buc-status-busy: #e74c3c;
  --buc-status-offline: #95a5a6;
  --buc-unread-marker: #2ecc71;
}

#wp-chat-frame * {
    scrollbar-color: rgba(0,0,0,.2) rgba(255,255,255,.1);
    scrollbar-width: thin
}

#wp-chat-frame canvas,#wp-chat-frame div,#wp-chat-frame footer,#wp-chat-frame form,#wp-chat-frame header,#wp-chat-frame img,#wp-chat-frame input,#wp-chat-frame input[type=text],#wp-chat-frame label,#wp-chat-frame li,#wp-chat-frame menu,#wp-chat-frame nav,#wp-chat-frame ol,#wp-chat-frame p,#wp-chat-frame section,#wp-chat-frame small,#wp-chat-frame span,#wp-chat-frame ul {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline
}

#wp-chat-frame ol,#wp-chat-frame ul {
    list-style: none
}

#wp-chat-frame a {
    color: inherit;
    text-decoration: none
}

#wp-chat-frame a.linkified {
  color: #039be5
}

#wp-chat-frame a.linkified:hover {
  text-decoration: underline
}

#wp-chat-frame embed,#wp-chat-frame img,#wp-chat-frame object,#wp-chat-frame svg {
    display: block;
    height: auto;
    max-width: 100%
}

#wp-chat-frame *,#wp-chat-frame ::after,#wp-chat-frame ::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    word-wrap: break-word
}

#wp-chat-frame {
    line-height: 1;
    font-family: "proxima-nova","Source Sans Pro",sans-serif;
    font-size: 16px;
    letter-spacing: .1px;
    text-rendering: optimizeLegibility;
    text-shadow: 1px 1px 1px rgba(0,0,0,.004);
    -webkit-font-smoothing: antialiased
}

#wp-chat-frame .buc__chat-launcher {
    z-index: 9999999;
    background: var(--buc-primary-color);
    width: 50px;
    height: 50px;
    position: fixed;
    right: 12px;
    bottom: 10px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.1),0 12px 28px rgba(0,0,0,.2);
    box-shadow: 0 2px 4px rgba(0,0,0,.1),0 12px 28px rgba(0,0,0,.2)
}

#wp-chat-frame .buc__chat-launcher .buc__chat-launcher-open {
    padding: 15px;
    opacity: 1
}

#wp-chat-frame .buc__chat-launcher .buc__chat-launcher-closed {
    padding: 15px;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 1
}

#wp-chat-frame .buc__chat-launcher .buc__chat-launcher-closed,#wp-chat-frame .buc__chat-launcher .buc__chat-launcher-open {
    width: 50px;
    height: 50px;
    -webkit-transition: opacity 100ms ease-in-out,-webkit-transform 100ms ease-in-out;
    -o-transition: opacity 100ms ease-in-out,transform 100ms ease-in-out;
    transition: opacity 100ms ease-in-out,transform 100ms ease-in-out;
    transition: opacity 100ms ease-in-out,transform 100ms ease-in-out,-webkit-transform 100ms ease-in-out
}

#buc__chat-tabs-container .buc__chat-tabs-list .buc__chat-bubble {
    z-index: 9999999;
    height: 40px;
    width: 40px;
    position: fixed;
    right: 19px;
    bottom: 70px;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.1),0 12px 28px rgba(0,0,0,.2);
    box-shadow: 0 2px 4px rgba(0,0,0,.1),0 12px 28px rgba(0,0,0,.2);
    cursor: pointer
}

#buc__chat-tabs-container .buc__chat-tabs-list .buc__chat-tab {
    z-index: 9999999;
    position: fixed;
    bottom: 0;
    right: 380px;
    width: 300px;
    height: 400px
}

@media only screen and (max-width:370px) {
    #buc__chat-tabs-container .buc__chat-tabs-list .buc__chat-tab {
        width: calc(100% - 70px)
    }
}

#buc__chat-tabs-container .buc__chat-tabs-list .buc__chat-bubble:hover .buc__chat-bubble-close {
    opacity: 1
}

#buc__chat-tabs-container .buc__chat-tabs-list .buc__chat-bubble:hover .buc__unread-count {
    opacity: 0
}

#buc__chat-tabs-container .buc__chat-tabs-list .buc__chat-bubble img {
    width: 100%;
    border-radius: 50%
}

#buc__chat-tabs-container .buc__chat-tabs-list .buc__chat-bubble .buc__chat-bubble-close {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background: #fff;
    color: #000;
    position: absolute;
    top: -4px;
    right: -4px;
    opacity: 0;
    -webkit-transition: opacity .2s;
    -o-transition: opacity .2s;
    transition: opacity .2s;
    -webkit-box-shadow: 0 3px 2px rgba(0,0,0,.3);
    box-shadow: 0 3px 2px rgba(0,0,0,.3)
}

#wp-chat-frame .buc__unread-count {
    position: absolute;
    color: #fff;
    background-color: var(--buc-unread-marker);
    border-radius: 50px;
    padding: 4px 6px;
    top: -8px;
    right: -5px;
    font-size: 11px;
    font-weight: 600;
    -webkit-box-shadow: 0 3px 2px rgba(0,0,0,.3);
    box-shadow: 0 3px 2px rgba(0,0,0,.3)
}

#buc__chat-tabs-container .buc__chat-tabs-list .buc__chat-bubble .buc__chat-bubble-close svg {
    padding: 4px
}

#wp-chat-frame .buc__icon {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    cursor: pointer;
    padding: 0
}

#wp-chat-frame .buc__icon.buc__icon-small {
    height: 12px;
    width: 12px
}

#wp-chat-frame .buc__icon.buc__right {
    float: right
}

#wp-chat-frame .buc__bold {
    font-weight: 600
}

#wp-chat-frame .buc__icon svg {
    fill: currentColor
}

#buc__chat-panel #buc__message-container::-webkit-scrollbar,#buc__users-panel #buc__contacts-cont::-webkit-scrollbar {
    width: 8px;
    background: 0 0
}

#buc__chat-panel #buc__message-container::-webkit-scrollbar-thumb,#buc__users-panel #buc__contacts-cont::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.3)
}

#wp-chat-frame #emoji-container>#emoji-popup>#emoji-popover-header::-webkit-scrollbar {
    height: 5px!important
}

#wp-chat-frame #emoji-container>#emoji-popup .emoji-popover-inner::-webkit-scrollbar {
    width: 5px!important
}

#buc__chat-panel .buc__input-bar .buc__emoji-disabled #twemoji-textarea-outer div:first-child {
    display: none!important
}

#buc__chat-panel .buc__input-bar .buc__media-disabled #twemoji-textarea-outer {
    width: 85%!important
}

#wp-chat-frame .twemoji-textarea-outer {
    width: 75%!important;
    font-size: 1em!important;
    background-color: #fff!important;
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
}

#wp-chat-frame .twemoji-textarea-outer #twemoji-textarea {
    white-space: nowrap!important;
    margin: 0!important;
    font-size: inherit!important;
    border-radius: initial!important
}

#wp-chat-frame .twemoji-textarea-outer img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important
}

#wp-chat-frame #emoji-container>#emoji-popup img.emoji {
    height: 20px!important;
    width: 20px!important;
    margin: 3px!important
}

#wp-chat-frame .twemoji-textarea-outer #btn-emoji-default img.emoji {
    height: 24px!important;
    width: 24px!important;
    margin: 0!important
}

#wp-chat-frame .twemoji-textarea-outer #btn-emoji-default {
    height: 24px!important;
    width: 24px!important;
    margin: 0 0 0 5px!important;
    padding: 0!important
}

#wp-chat-frame .twemoji-textarea-outer #btn-emoji-default:focus,#wp-chat-frame .twemoji-textarea-outer #twemoji-textarea:focus {
    outline: 0!important
}

.buc__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.buc__animate__slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp
}

.buc__animate__slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown
}
</style>
